/* eslint-disable camelcase*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AmigosContainer, ButtonContainer, CheckMark, CustomCheckbox, CustomMenuItem, FieldTitle, ImageContainer, InviteContainer, LocationContainer, NameContainer, SaveButton, ScrollableContainer, StyledInput, TitleContainer, TopContainer, SaveButtonV2 } from "../CreateEvent/styles";

import { ActivityDropdown, ActivityTypeContainer } from "../CreateChallengeForm/styles";
import { FirstPart } from "../BehaviorChallenge/styles";
import { RadioButtonContainer } from "../CreateRecipe/styles";
import RadioButton from "../CustomRadioButton";
import { withTranslation } from 'react-i18next';
import {InviteAmigo} from './styles';
import { ImageUrl, WellnessLevels } from '../../utils/constants';
import { toast } from 'react-toastify';
import { fetchApi } from '../../utils/methods';
import { GetQuestionByCategoryId, GetWellnessGoals } from '../../redux/constants/apiConstants';
import { StyledLabel } from '../CreateEvent/style';
import CommonImageUploader from '../common/CommonImageUploader/CommonImageUploader';
import { UploadIcon, closeIcon } from '../../utils/icons';

class FormEditVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video_categories: [],
      wellnessGoals:[],
      wellness_goal:null,
      wbaQuestions:[],
      showWbaQue:false,
      question:null,
      levels:null,
      selectedOption: 0,
      isDisabled: this.props.isEdit ? false : true,
      imgSrc:null
    }
  }

  componentDidMount(){
    this.setState({...this.props.state},()=>{
      this.getWellnessGoals();
    })

    this.props.mandatoryFields.map((field) => {

      if (!this.state[field]) {

        this.setState({isDisabled:  true});
      }
      else if ('title' === field || 'video_url' === field) {
        if (this.state[field].trim() == '') {

          this.setState({isDisabled: true});
        }
      }
      else
      {
        this.setState({isDisabled: false})

      }
    });

    if(this.props.isEdit){
      this.setState({
        isDisabled: false
      })
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        ...this.props.state
      })
    }
  }
  toggleRadioButton = (e) => {
    this.setState({ selectedOption: parseInt(e.target.value,10) }, () => { });
  };

  onSelectTheme = (value) => {
    this.setState({
      themeName: value.theme_text,
      themeMonth: value.month,
      themeId: value.id,
    });
  };

  validateUrl = (urlValue) => {
    let res = urlValue.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g);
    if(res == null)
      return false;
    else
      return true;
  }

  onChangeInput = (e, isMandatory) => {

    if(isMandatory && !e.target.value.length){
      this.setState({
        isDisabled: true
      })
    }
    this.setState({
      [e.target.name]: e.target.value,
    }, ()=>{
      if( 
        !this.props.isEdit && (
          ( !this.state.fitness_level ) || ( !this.state.muscle_group  ) || 
          !this.state.video_url || !this.state.title || !this.state.video_categories.length)
      )
      {
        this.setState({
          isDisabled: true
        })
      }
      else if(this.props.isEdit && (!this.state.video_url || !this.state.title || !this.state.video_categories.length) )
      {
        this.setState({
          isDisabled: true,
        })
      }
      else{
        this.setState({
          isDisabled: false
        })
      }
    });
  };

  onSelect = (key, item) => {
    // if(item.id === 'none' && (key === 'fitness_level' ) ){
    //   this.setState({
    //     isDisabled: true
    //   })
    // }
    // else 


    this.setState({
      [key]: item.id
    },()=>{
      if(!this.state.muscle_group || !this.state.title || !this.state.video_categories.length || !this.state.video_url ){
        this.setState({
          isDisabled: true
        })
      }
      else{
        this.setState({
          isDisabled: false
        })
      }
    });
  };

  selectAllCategories = (e, fitnessCategories) => {
    e.preventDefault();
    const { video_categories } = this.state;
    if (video_categories.length === fitnessCategories?.length) {
      this.setState({
        video_categories: [],
        isDisabled: true
      })
    }
    else {
      let arr = [];
      fitnessCategories && fitnessCategories?.map((data) => {
        arr.push(data.id);
      });
      this.setState({
        video_categories: arr,
        isDisabled: false
      })
    }
  };

  onChangeCategory = (id) => {
    const { video_categories } = this.state;

    if( !this.state.muscle_group || !this.state.title || !this.state.video_url ){
      this.setState({
        isDisabled: true
      })
    }
    else{
      this.setState({
        isDisabled: false
      })
    }

    if (video_categories.includes(id)) {
      let index = video_categories.findIndex((item) => item === id);
      if (index > -1) {
        video_categories.splice(index, 1);
      }
    } else {
      video_categories.push(id);
    }
    this.setState({
      video_categories: video_categories
    },()=>{
      if(video_categories.length === 0)
      {
        this.setState({
          isDisabled: true
        })
      }

    })

  };


  getWellnessGoals = async()=>{
    try {
      const res = await fetchApi(`${GetWellnessGoals}`, "GET",null, {});
      if(res){
        if(this.state.selectedWellnessGoal){
          const selectedWellnessGoal = res.data.find((wellnessGoal) => wellnessGoal.id === this.props.state.selectedWellnessGoal);
          this.setState({wellnessGoals:res.data, wellness_goal:selectedWellnessGoal});

        }else{
          this.setState({wellnessGoals:res.data});

        }

        if(this.state.selectedQuestionId){
          this.getWBAQuestions(this.props.state.selectedWellnessGoal);
        }

        if(this.state.selectedLevel){
          const selectedLevel = WellnessLevels.find((wellnessLevel) => wellnessLevel.level === this.props.state.selectedLevel);
          this.setState({levels:selectedLevel})

        }

      }
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
    }
  }

  getWBAQuestions = async(categoryId)=>{
    try {
      const res = await fetchApi(`${GetQuestionByCategoryId + "/" + categoryId}`, "GET",null, {});
      if(res.data.length > 0){
        if(this.state.selectedQuestionId){
          const selectedQuestion = res.data.find((question) => question.id === this.state.selectedQuestionId);
          this.setState({wbaQuestions:res.data, showWbaQue:true, question:selectedQuestion})

        }else{
          this.setState({wbaQuestions:res.data, showWbaQue:true})
        }

      }else{
        this.setState({wbaQuestions:[], showWbaQue:false})

      }
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
    }
  }

  onDropDownSelect = (key, item) => {
    this.setState({
      [key]: item
    },()=>{key === "wellness_goal" && this.getWBAQuestions(item.id)});
  };

  handleFormSubmit = (e) =>{
    const {onSubmit} = this.props;
    e.preventDefault();

    if(!this.validateUrl(this.state.video_url)){
      toast.error("Please enter valid Video URL");
      this.setState({
        video_url: '',
        isDisabled: true
      })
      return false
    }
    else{
      onSubmit(e, this.state);
    }
  }

  handleImageChange = (imgSrc) => {
    this.setState({ imgSrc });
  };

  handleRemoveImage = () => {
    this.setState({ imgSrc: "" });
  };

  checkBase64(image) {
    if(image) {
      let arr = image.split('/');
      if(arr[0] !== 'video-category-image') {
        return true;
      }
      else {
        return false;
      }
    }
  }

  render() {
    const { fields, buttonText, /* mandatoryFields,  *//* onSubmit, */ /*  themes, */ t, video_category_id, fieldsTitle, themesHeaderAll } = this.props;
    const { selectedOption, themeName, themeMonth, video_categories,imgSrc } = this.state;
    // mandatoryFields.map((field) => {
    //   if (!this.state[field]) {
    //     this.setState({isDisabled: true});
    //   }
    //   else if ('title' === field || 'video_url' === field) {
    //     if (this.state[field].trim() == '') {
    //       this.setState({isDisabled: true});
    //     }
    //   }
    // });
    window.console.log("testing data",this.state.isDisabled, fieldsTitle, fields);
    return (
      <form onSubmit={(e) => this.handleFormSubmit(e)}>
        {
          fieldsTitle.map((field) => {
            if (field.type === 'text') {
              return (
                <TitleContainer key={field.name} padding={'30px 0px 30px 0px'} noBorder={1}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={(e)=>this.onChangeInput(e, field.mandatory)}
                    value={this.state[field.name]}
                  />
                </TitleContainer>
              )
            }
          })
        }

        <AmigosContainer padding={'30px 0px 30px 0px'} noBorder={1}>
          <TopContainer>
            <FieldTitle>{t("Select Category (Select all the applicable)")}<span style={{color: 'red'}}>*</span> :</FieldTitle>
            {video_category_id && video_category_id?.length > 0 &&
              <ButtonContainer>
                <SaveButton color="#159fc9" onClick={(e) => this.selectAllCategories(e, video_category_id)}>
                  <i className="fas fa-check" />
                  {
                    video_categories && video_categories.length === video_category_id && video_category_id?.length ? t('deselect all categories') : t('select all categories')
                  }
                </SaveButton>
              </ButtonContainer>
            }
          </TopContainer>
          <InviteContainer bgColor={"#fff"}>
            <ScrollableContainer>
              <LocationContainer minHeight={video_category_id && video_category_id?.length}>
                {
                  video_category_id && video_category_id?.length > 0 ? video_category_id?.map((tag, index) => (

                    <InviteAmigo key={index}>
                      <CustomCheckbox>
                        <input
                          type="checkbox"
                          checked={video_categories && video_categories.includes(tag['id'])}
                          onChange={() => this.onChangeCategory(tag['id'])}
                        />
                        <CheckMark checked={video_categories && video_categories.includes(tag['id'])} />
                      </CustomCheckbox>
                      <NameContainer onClick={() => this.onChangeCategory(tag['id'])}>
                        <span>{tag['category_name']}</span>
                      </NameContainer>
                    </InviteAmigo>

                  )) : null
                }
              </LocationContainer>
            </ScrollableContainer>
          </InviteContainer>
        </AmigosContainer>
        {
          fields && fields.map((field) => {
            if (field.type === 'drop-down') {
              let newTitle = field.placeholder;
              if (this.props[field.key] && this.state[field.key]) {
                const selectedCategory = this.props[field.key].filter((category) => category.id === this.state[field.key]);
                newTitle = selectedCategory ? (selectedCategory[0] && selectedCategory[0].category_name) : (selectedCategory[0] && selectedCategory[0].category_name);
              }

              return (
                <TitleContainer key={field.name} padding={'30px 0px 30px 0px'} noBorder={1}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={newTitle}
                    id={`dropdown-${field.name}`}
                    onSelect={(index) => this.onSelect(field.key, this.props[field.key][index])}
                  >
                    {
                      this.props[field.key].map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onClick={() => {this.onSelect(field.key, item)}}
                          active={this.state[field.key] === item.id}
                        >
                          {t(item.category_name)}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )
            }
            else if (field.type === 'url') {
              return (
                <TitleContainer key={field.name} padding={'30px 0px 30px 0px'} noBorder={1}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={(e) => this.onChangeInput(e, field.mandatory)}
                    value={this.state[field.name]}
                  />
                </TitleContainer>
              )
            }else if(field.type === 'wellness_goal') {

              return (
                <TitleContainer key={field.name} padding={'0px 0px 30px 0px'} style={{borderBottom:"unset"}}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={this.state[field.key] && this.state[field.key].name && this.state[field.key].name || field.placeholder}
                    id={`dropdown-${field.name}`}
                    onSelect={(index) => this.onDropDownSelect(field.key, this.state.wellnessGoals[index])}
                  >
                    {
                      this.state.wellnessGoals.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onSelect={() => this.onDropDownSelect(field.key, item)}
                          active={this.state[field.key] && this.state[field.key].id && this.state[field.key].id === item.id}
                        >
                          {item.name}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )}else if(field.type === 'sub_level' && this.state.showWbaQue) {
               
              return (
                <TitleContainer key={field.name} padding={'0px 0px 30px 0px'} style={{borderBottom:"unset"}}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={this.state[field.key] && this.state[field.key].question && this.state[field.key].question || field.placeholder}
                    id={`dropdown-${field.name}`}
                    onSelect={(index) => this.onDropDownSelect(field.key, this.state.wbaQuestions[index])}
                  >
                    {
                      this.state.wbaQuestions.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onSelect={() => this.onDropDownSelect(field.key, item)}
                          active={this.state[field.key] && this.state[field.key].id && this.state[field.key].id === item.id}
                        >
                          {item.question}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )}else if(field.type === 'level') {
               
              return (
                <TitleContainer key={field.name} padding={'0px'}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <ActivityDropdown
                    title={this.state[field.key] && this.state[field.key].level && this.state[field.key].level || field.placeholder}
                    id={`dropdown-${field.name}`}
                    onSelect={(index) => this.onDropDownSelect(field.key, WellnessLevels[index])}
                  >
                    {
                      WellnessLevels.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onSelect={() => this.onDropDownSelect(field.key, item)}
                          active={this.state[field.key] && this.state[field.key].id && this.state[field.key].id === item.id}
                        >
                          {item.level}
                        </CustomMenuItem>
                      ))
                    }
                  </ActivityDropdown>
                </TitleContainer>
              )
            }else if(field.type === 'thumbnail') {
              return(
                <>
                  <StyledLabel color="#005C87" margin="0px">{this.props.t("Thumbnail image")}{<span>*</span>}</StyledLabel>
                  <CommonImageUploader
                    imgSrc={this.checkBase64(imgSrc) ? imgSrc : imgSrc ? `${ImageUrl}/${imgSrc}` : ""}
                    t={t}
                    closeIcon={closeIcon}
                    UploadIcon={UploadIcon}
                    onImageChange={this.handleImageChange}
                    onRemoveImage={this.handleRemoveImage}
                  />
                </>
              )
            }
            if (field.type === 'text') {
              return (
                <TitleContainer key={field.name} padding={'30px 0px 30px 0px'} noBorder={1}>
                  <FieldTitle>{t(field.label)} { field.mandatory ? field.mandatory && <span style={{color: 'red'}}>*</span> : ''}</FieldTitle>
                  <StyledInput
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={(e)=>this.onChangeInput(e, field.mandatory)}
                    value={this.state[field.name]}
                  />
                </TitleContainer>
              )
            }

          })
        }
        <ImageContainer padding={'25px 0px 25px 0px'} border={"1"}>
          <FirstPart>
            <FieldTitle>{t('Add a Theme')}:</FieldTitle>
            <RadioButtonContainer justifyContent={"start"}>
              <RadioButton id="11" handler={this.toggleRadioButton} value={1} isChecked={selectedOption === 1} label={t('Yes')} challengeLeaderBoard={true} />
              <RadioButton id="12" handler={this.toggleRadioButton} value={0} isChecked={selectedOption === 0} label={t('No')} challengeLeaderBoard={true} />
            </RadioButtonContainer>
          </FirstPart>
        </ImageContainer>
        {selectedOption === 1 ? <TitleContainer addMargin padding={'30px 0px 30px 0px'} noBorder={1}><ActivityTypeContainer width="100%" noChildWidth={1} >
          <FieldTitle>{t('Select a theme')}:</FieldTitle>
          <ActivityDropdown
            title={<div className="title">{themesHeaderAll.length === 0 ? t('No Themes Available') : (!themeName && !themeMonth) ? t('Select a theme') : `${themeMonth}, ${themeName}`}</div>}
            id={"surveyType"}
          >{
              themesHeaderAll && themesHeaderAll.length > 0 && themesHeaderAll.map((list) => (
                <CustomMenuItem key={list.id} active={themeName == list.theme_text} /* onSelect */ onClick={() => this.onSelectTheme(list)}>{list.month}, {list.theme_text}</CustomMenuItem>
              ))
            }
          </ActivityDropdown>
        </ActivityTypeContainer></TitleContainer> : null}
        <ImageContainer padding={'0px'} border={"1"} >
          <SaveButtonV2 type="submit" addMargin disabled={this.state.isDisabled}>{t(buttonText)}</SaveButtonV2>
        </ImageContainer>
      </form>
    )
  }
}

FormEditVideo.propTypes = {
  fields: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  mandatoryFields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  journey_category_id: PropTypes.array,
  themes: PropTypes.array,
  themesHeaderAll: PropTypes.array,
  t: PropTypes.func,
  video_category_id: PropTypes.array,
  fieldsTitle: PropTypes.array.isRequired,
  isEdit: PropTypes.bool,
};

export default (withTranslation()(FormEditVideo));
